//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: "Questrial", sans-serif;

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$body-color: #000000; // The default text color

$primary: #0d6efd;
$secondary: #6c757d;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  // xl: 1200px,
  // xxl: 1400px
  xl: 1400px,
  xxl: 1800px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  // xl: 1140px,
  // xxl: 1320px
  xl: 1340px,
  xxl: 1720px
);