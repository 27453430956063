/* MEDIA BREAKPOINTS
-------------------------------------------------- */

// ### DOWN
//
// From large to small devices

// No media query necessary for xs breakpoint as it's effectively `@media (max-width: 0) { ... }`
@include media-breakpoint-down(sm) {
  #about-why-us {
    .title {
      position: relative;
      z-index: 1;
      top: 0;
      left: 0;
      margin-bottom: -1rem;
      transform: none;
      line-height: 1.2;
      width: auto !important;
      height: auto !important;
      border-radius: 2rem;
    }
  }

  #about-why-us {
    .item {
      height: auto;
    }
  }
}

@include media-breakpoint-down(md) {}

@include media-breakpoint-down(lg) {
  #about {

    .mission,
    .vision {
      .text {
        position: absolute;
        top: auto;
        left: 50%;
        bottom: -8%;
        z-index: 1;
        transform: translateX(-50%);
      }
    }
  }

  #about-why-us {
    .title {
      line-height: 1.2;
      width: 150px;
      height: 150px;
    }
  }

}

@include media-breakpoint-down(xl) {}

@include media-breakpoint-down(xxl) {}

// ### UP
//
// From small to large devices

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
@include media-breakpoint-up(sm) {}

@include media-breakpoint-up(md) {
  .display-5 {
    font-size: calc(0.8rem + 2.1vw);
  }

  #about {
    .vision {
      height: 488px;
    }

    .mission {
      height: 488px;
    }
  }

  #about-why-us {
    .title {
      width: 200px;
      height: 200px;
    }

    .item {
      height: 480px;
    }
  }

  #authorized {
    .list-name {
      .col-md-4:nth-child(2) {

        &:after {
          right: 0;
        }

        &:before {
          left: 0;
        }
      }
    }
  }

  .wrap {
    height: 550px;
    width: 500px;
  }

  #how {
    &:before {
      width: 96vw;
    }
  }
}


@include media-breakpoint-up(lg) {}

@include media-breakpoint-up(xl) {
  #authorized {
    .list-name {
      .col-md-4:nth-child(2) {

        &:after {
          right: 5%;
        }

        &:before {
          left: -12%;
        }
      }
    }
  }
  #product-listing {
    .card {
      min-height: 600px;
    }
  }
}

@include media-breakpoint-up(xxl) {
  #authorized {
    .list-name {
      .col-md-4:nth-child(2) {

        &:after {
          right: 12%;
        }

        &:before {
          left: -18%;
        }
      }
    }
  }
}

// ### ONLY
//
// Only breakpoints

@include media-breakpoint-only(xs) {
  #authorized {
    .list-name {
      .col-md-4:nth-child(2) {

        &:after,
        &:before {
          display: none;
        }
      }
    }

    &.supporting-brand img {
      height: auto;
    }
  }

  .wrap {
    height: 400px;
    width: 360px;
  }

  .problem-item {
    width: 80px;

    &:nth-child(2) {
      left: 75%;
    }

    &:nth-child(5) {
      width: 170px;
      height: 170px;
    }
  }

  #why-industrial {
    height: auto;
    margin-bottom: 3rem;
    background-size: 300%;
    background-position: top center;
  }

  #how:before {
    width: 95vw;
  }

  // #solution-robot {
  //   .slick-list {
  //     width: 375px;
  //   }
  // }
}

@include media-breakpoint-only(sm) {}

@include media-breakpoint-only(md) {
  #why-industrial {
    margin-bottom: 22rem;
  }

  #authorized {
    &.supporting-brand img {
      height: auto;
    }
  }

  // #solution-robot {
  //   .slick-list {
  //     width: 768px;
  //   }
  // }
}

@include media-breakpoint-only(lg) {}

@include media-breakpoint-only(xl) {}

@include media-breakpoint-only(xxl) {}