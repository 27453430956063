//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// Font Awesome
@import "fa-variables";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/solid";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

.slick-dots {
  z-index: 10;
}

.navbar-toggler-icon {
  background-image: none !important;
  background-color: var(--bs-gray-800);
  height: 3px;
  width: 25px;
  margin: 14px 0;
  position: relative;
  transition: all 0.35s ease-out;
  transform-origin: center;
}

.navbar-toggler-icon::before {
  display: block;
  background-color: var(--bs-gray-800);
  height: 3px;
  content: "";
  position: relative;
  top: -9px;
  transition: all 0.15s ease-out;
  /*taken down to hide quicker*/
  transform-origin: center;
}

.navbar-toggler-icon::after {
  display: block;
  background-color: var(--bs-gray-800);
  height: 3px;
  content: "";
  position: relative;
  top: 6px;
  transition: all 0.35s ease-out;
  transform-origin: center;
}

.navbar-dark .navbar-toggler-icon,
.navbar-dark .navbar-toggler-icon::before,
.navbar-dark .navbar-toggler-icon::after {
  background-color: var(--bs-gray-100);
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  // transform: rotate(45deg);
  transform: rotate(90deg);
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon::before {
  // opacity: 0;
  transform: rotate(0deg) translatey(-1px);
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
  // transform: rotate(-90deg) translateX(7px);
  transform: rotate(0deg) translatey(1px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

.btn-big {
  font-size: 22px;
  border-radius: 30px;
}

#banners {
  .item {
    height: calc(100vh - 82px);
  }

  .banner-text {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .bg-overflow {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  &:after {
    content: '';
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 90%);
    background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 90%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 90%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#ffffff", GradientType=1);
  }

  &.page-banner {
    height: 768px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &>.container {
      top: 50%;
      transform: translateY(-50%);
    }

    &.contact-us {
      height: 442px;
    }
  }
}

#products {
  //margin-top: -6rem;
  position: relative;
  z-index: 100;
}

#brand-list {
  img {
    height: 40px;
  }

  .slick-prev,
  .slick-next {
    &:before {
      font-family: "Font Awesome 6 Free";
      color: $black;
    }
  }

  .slick-prev {
    left: -30px;

    &:before {
      content: "\f053";
    }
  }

  .slick-next {
    right: -30px;

    &:before {
      content: "\f054";
    }
  }
}

#services {
  .link-viewmore {
    font-size: 18px;
    text-decoration: none;
    color: $black;

    i {
      margin-left: 6px;
    }

    &:hover {
      color: #1F5299;
    }
  }
}

#service-items {
  .link {
    p {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        left: 0;
        bottom: 0;
        background-color: $white;
        opacity: 0;
      }
    }

    &:hover {
      p:after {
        opacity: 1;
      }
    }
  }
}

#why-us {
  .card {
    border: 0;
    box-shadow: 0 4px 10px 3px rgba(131, 138, 179, 0.24);
  }

  img {
    height: 122px;
  }
}

#brand-list {
  .slick-dots {
    bottom: -2rem;
  }
}


#about {
  .vision {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    height: 470px;

    .text {
      position: absolute;
      left: 40%;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .mission {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
    height: 470px;

    .text {
      position: absolute;
      right: 40%;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}

#about-why-us {
  .title {
    line-height: 46px;
    position: absolute;
    width: 280px;
    height: 280px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    background: radial-gradient(162.33% 162.33% at 91.25% -10.34%, #274B75 0%, #498CDB 100%);
  }

  position: relative;

  .item {
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

#authorized {
  .list-name {
    .col-md-4:nth-child(2) {
      position: relative;

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: $black;
      }

      &:after {
        right: 15px;
      }

      &:before {
        left: -16px;
      }
    }
  }

  .logo-list {
    img {
      height: 40px;
    }
  }

  &.supporting-brand {
    img {
      height: 60px;
    }
  }
}

#product-listing {
  .card {
    border: 0;
    min-height: 478px;
    border-radius: 28px;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: 0 center;
    overflow: hidden;
    background: linear-gradient(180deg, #F5F5F5 0%, #E5DED3 100%);
    .logo {
      height: 36px;
    }
    .card-body {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom center;
    }

    .btn {
      font-size: 18px;

      i {
        color: #F8D509;
        font-size: 12px;
        margin-left: 6px;
      }

      &:hover {
        color: $white;
        background-color: #F8D509;

        i {
          color: $white;
        }
      }
    }
    .img-bg {
      width: -webkit-fill-available;
    }
  }
}

#products-detail {
  .product-search {
    .input-group-text {
      border-radius: 30px 0 0 30px;
      background-color: $white;
    }

    input.form-control {
      border-radius: 0 30px 30px 0;
    }

    .input-group-text,
    input.form-control {
      border-color: #858d99;
    }

    .btn {
      color: $white;

      &:hover {
        background-color: #0656cd;
      }
    }
  }

  .btn {
    color: $black;
    border-color: #1f5299;

    &:hover {
      color: $white;
    }
  }
}

#industrial-solution {
  h2:after {
    content: '';
    position: absolute;
    width: 20%;
    border-top: 2px solid #F8D509;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
}

//#solution-robot,
//#solution-robot-2 {
//  overflow: hidden;
  // .slick-list {
  //   width: 1830px;
  //   right: 0;
  //   margin-left: auto;
  // }
//  .slick-slide {
//    margin: 1rem;
//    .card {
//      border-width: 2px;
//      border-color: #F8D509;
//      box-shadow: 0 4px 20px 3px #E5DED3;
//      h3 {
//        color: #ED1C24;
//      }
//    }
//  }
//}

#solution-robot {
 // overflow:hidden;
 // .row {
 //   padding-left: 100px;
 //   width: calc(100vw + 100px);
 // }
  .card {
    border-width: 2px;
    border-color: #F8D509;
    box-shadow: 0 4px 20px 3px #E5DED3;
    h3 {
      color: #ED1C24;
    }
  }
}

#why-industrial {
  margin-top: 3rem;
  margin-bottom: 8rem;
  height: 330px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .card {
    border: 2px solid #F8D509;
  }
}

#how {
  &:before {
    content: '';
    width: 80vw;
    height: 100%;
    border-style: solid;
    border-color: rgba(229, 222, 211, 1);
    border-width: 2px 2px 2px 0;
    border-radius: 0 35px 35px 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
}

#blog-list {
  .card-body {
    .subtitle {
      color: #737373;
    }

    .btn {
      color: #ED1F25;
      text-decoration: none;

      &:hover {
        color: #0d6efd;
        text-decoration: underline;
      }
    }
  }

  .pagination {
    .page-link {
      border: 0;
      color: $black;
      font-size: 30px;
      opacity: .4;

      &:hover {
        opacity: 1;
        background-color: transparent;
      }
    }
  }
}

#careers {
  .card {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    .available {
      color: #2BB91F;
      font-size: 20px;

      &.not {
        color: #E33B3B;
      }
    }
  }
}

footer {
  background-color: #4b619e;

  // color: $white;
  p {
    font-size: 14px;
  }

  .footer-links {
    h6 {
      margin-bottom: 1rem;
      font-weight: 700;
    }

    li {
      font-size: .9rem;
      margin-bottom: .8rem;

      a {
        color: $white;
        text-decoration: none;
      }
    }
  }
}

.wrap {
  position: relative;
  margin: 0 auto;
  height: 532px;
  width: 546px;
}

.problem-item {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 90px;

  h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    height: 80%;
    width: 80%;
    background-color: $white;
  }

  p {
    color: #265AAB;
    font-size: 12px;
  }

  &:nth-child(1) {
    left: 50%;
    transform: translate(-50%, 0);
  }

  &:nth-child(2) {
    top: 50%;
    left: 84%;
    transform: translate(0, -50%);
  }

  &:nth-child(3) {
    top: 50%;
    transform: translate(0, -50%);
  }

  &:nth-child(4) {
    top: 80%;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &:nth-child(5) {
    width: 268px;
    height: 268px;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    background-color: #F8D509;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#findus {
  a {
    text-decoration: none;
    color: $white;
  }
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

// @import "components/slider";
@import "components/slick";

//---------------------------------------------
// 4.Responsive
//---------------------------------------------

@import "responsive";